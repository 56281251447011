import React from "react"

const Services = () => {
  return (
    <section className="services-list">
      <h2>Usługi</h2>
      <ul>
        <li>Tłumaczenia uwierzytelnione z języka i na język czeski</li>
        <li>
          Tłumaczenia zwykłe z języka i na język czeski
          <p>(tłumaczenia o terminologii medycznej oraz technicznej po wcześniejszej weryfikacji tekstu źródłowego)</p>
        </li>
      </ul>

      <h3>Dodatkowo</h3>
      <ul>
        <li>Tłumaczenia zwykłe z języka słowackiego</li>
        <li>Tłumaczenia zwykłe z języka niemieckiego</li>
      </ul>
    </section>
  )
}

export default Services