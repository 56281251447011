import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Main from "../components/Main"
import Services from "../components/Services"
import Contact from "../components/Contact"

const IndexPage = () => (
  <Layout>
    <SEO title="Strona główna" />
    <Main />
    <Services />
    <Contact />
  </Layout>
);

export default IndexPage;
