import React from "react"
import { FaPhone, FaEnvelope } from 'react-icons/fa';

const Contact = () => {
  return (
    <address className="contact-section">
      <h2>Kontakt</h2>
      <ul>
        <li>
          <FaEnvelope size={18} />
          <a href="mailto:bozenaparkitna@poczta.onet.pl">
            bozenaparkitna@poczta.onet.pl
          </a>
        </li>
        <li>
          <FaPhone size={18} />
          <a href="tel:+48663338323">
            663 338 323
          </a>
        </li>
      </ul>

      <h3>Adres</h3>
      <p>
        Bieżeń, ul. Kostrzyna 6 <br/>
        42-133 Węglowice
      </p>

    </address>
  )
}

export default Contact