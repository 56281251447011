import React from "react"

const Main = () => {
  return (
    <main className="main-section">
      <section className="card">
        <h1>
          mgr Bożena Kiepura – Parkitna
        </h1>

        <h3>
          Tłumacz przysięgły języka czeskiego <br/>
          nr uprawnienia : TP/4924/05 <br/>
          Uzyskanie uprawnienia: 05.09.1995
        </h3>
      </section>


    </main>
  )
}

export default Main